import { Form, Input } from "antd";
import { AyrmeshButton } from "../../../../shared-components/ayrmesh-button/AyrmeshButton";
import './LoginForm.css';

type LoginFormProps = {
  handleSubmit: any;
  isLoading: boolean;
}

export function LoginForm({handleSubmit, isLoading}: LoginFormProps) {
  return (
    <div className="login-form-container">        
      <Form
        layout="vertical"
        name="basic"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item
         label="Username"
         name="username"
         rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item style={{marginBottom: 10}}>
          <AyrmeshButton loading={isLoading} block type="primary" htmlType="submit">
            Login
          </AyrmeshButton>
        </Form.Item>
      </Form>
    </div>

  );
}

LoginForm.defaultProps = {
  isLoading: false,
};
